<template>
  <c-box
      style="background-color: white"
      borderRadius="5px"
      mr="20px"
      p="5"
      mb="30px"
  >
    <c-flex @click="goToProfile(speaker.user.id)">
      <c-image
          v-if="speaker.user"
          style="border-radius: 8px"
          h="50px"
          w="50px"
          mb="3"
          alt="profile picture"
          :src="speaker.user.profilePhoto ? speaker.user.profilePhoto : require('@/assets/img/user.jpg')"
      />
      <c-image
          v-else
          style="border-radius: 8px"
          h="50px"
          w="50px"
          mb="3"
          alt="profile picture"
          :src="require('@/assets/img/user.jpg')"
      />
      <c-flex ml="3" flexDirection="column" align="start">
        <c-text fontSize="14px" fontWeight="700"
        >{{ speaker.firstName ? speaker.firstName : speaker.firstname }} {{ speaker.lastName ? speaker.lastName : speaker.lastname }}</c-text
        >
        <!-- <c-text class="company_name" v-if="speaker.user && speaker.user.companies.length" fontSize="13px" fontWeight="600">{{
            speaker.user.companies[0].name
          }}</c-text> -->
        <c-text class="company_name" fontSize="13px" fontWeight="600">{{
            speaker.companyName
          }}</c-text>

        <!-- <c-text v-if="speaker.user && speaker.user.companies && speaker.user.companies[0].teams" fontSize="13px" mb="3" fontWeight="400">{{
          speaker.user.companies[0].teams[0].position
        }}</c-text> -->
        <!-- <c-text fontSize="13px" fontWeight="600">{{
            speaker.position
          }}</c-text> -->

      </c-flex>
    </c-flex>
    <c-flex>
      <c-button
          v-if="speaker.user"
          variant-color="vc-orange"
          variant="outline"
          size="sm"
          @click="sendQuickMessage(speaker.user)"
      >Message</c-button
      >
      <c-button
          v-if="speaker.user"
          ml="5"
          variant-color="vc-orange"
          size="sm"
          @click="follow"
      >Follow</c-button
      >
    </c-flex>
  </c-box>
</template>

<script>
import messagingMixin from '@/mixins/messaging.js';
import {followUser} from "@/services/user";

export default {
  name: 'SpeakerCard',
  props: ['speaker', 'type'],
  mixins: [messagingMixin],
  methods: {
    async follow() {
      this.btnLoading = true;
      const userId = this.speaker.user.id;

      try {
        await followUser(userId);
        const audio = new Audio(require('@/assets/sounds/tone.mp3'));
        audio.play();
        this.btnLoading = false;
        this.followed = true;
      } catch (e) {
        this.btnLoading = false;
        console.log(e);
      }
    },
    goToProfile(userId) {
      console.log(userId);
      this.$router.push({ name: 'Profile', params: { userId } });
    },
  }
};
</script>

<style scoped>
.company_name{
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  height: 18px;
}
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
