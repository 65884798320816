import { render, staticRenderFns } from "./SingleEvent.vue?vue&type=template&id=5cf4c070&scoped=true"
import script from "./SingleEvent.vue?vue&type=script&lang=js"
export * from "./SingleEvent.vue?vue&type=script&lang=js"
import style0 from "./SingleEvent.vue?vue&type=style&index=0&id=5cf4c070&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf4c070",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CSpinner: require('@chakra-ui/vue').CSpinner, CFlex: require('@chakra-ui/vue').CFlex, CImage: require('@chakra-ui/vue').CImage, CIcon: require('@chakra-ui/vue').CIcon, CText: require('@chakra-ui/vue').CText, CIconButton: require('@chakra-ui/vue').CIconButton, CAvatar: require('@chakra-ui/vue').CAvatar, CButton: require('@chakra-ui/vue').CButton, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CBox: require('@chakra-ui/vue').CBox, CStack: require('@chakra-ui/vue').CStack, CLink: require('@chakra-ui/vue').CLink, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogHeader: require('@chakra-ui/vue').CAlertDialogHeader, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CModalBody: require('@chakra-ui/vue').CModalBody, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal})
